@import 'assets/scss/_colors';

.omny-button.btn {
  background: linear-gradient(91.73deg, #326fcd 0%, #183fb9 100%);
  box-shadow: 0px 4px 20px rgba(24, 63, 185, 0.2);
  border-radius: 8px;
  font-size: 1.25rem;
  height: 60px;
  font-weight: 700;
  font-family: 'Lato', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  .spinner-border-sm {
    margin-right: 0.5rem;
  }

  &.btn-warning {
    background: none;
    background-color: #ffc96e;
  }

  &.btn.disabled,
  &.btn:disabled {
    background: #c6c6c6;
    color: $mineShaft;
    opacity: 1;
    border-color: transparent;
    font-weight: 700;
  }
}
