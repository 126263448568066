#footer {
  background-color: #a73400;

  .triagle {
    position: absolute;
    top: 15px;
    right: 11px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 9px 8px;
    border-color: transparent transparent #fff transparent;
  }

  .footer-content {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
    color: #ffffff;
    img {
      margin-left: 4px;
      margin-right: 4px;
    }
  }
  .bg_black {
    background-color: #a73400;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    color: #d6d6d6;
    font-size: 18px;
    border-top: #999999 solid 1px;
    .text-primary-hover a {
      color: #ffe600 !important;
    }
  }
  .back-to-top-btn {
    position: fixed;
    bottom: 10px;
    right: 20px;
    width: 40px;
    height: 40px;
    display: none;
    text-align: center;
    border-radius: 3px;
  }
  .images-app {
    padding-bottom: 1.5rem !important;
  }
  .btn.btn-gradient-primary {
    background: linear-gradient(to right, #1f61eb 0%, #94b4f5 100%);
    border: none;
    color: #fff;
    box-shadow: 0 5px 20px rgba(52, 58, 64, 0.1);
    padding: 5px 13px;
    z-index: 10;
  }
}
