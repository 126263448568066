@import 'assets/scss/_colors';

.omny-outlinedButton.btn {
  border: 2px solid $mariner;
  border-radius: 8px;
  font-size: 1.25rem;
  height: 60px;
  font-weight: 700;
  color: $mariner;

  &:focus {
    background-color: transparent;
  }
  &:hover {
    background-color: transparent;
  }
}
