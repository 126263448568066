.home-page {
  .hero-5 {
    padding: 200px 0 22px;
    position: relative;
    background-image: url(../../assets/img/bg_top.png);
    background-repeat: no-repeat;
    width: 100%;
    background-position: bottom center;
    background-size: cover;
    min-height: 824px;
  }
  .bg-light {
    background-color: #ffffff !important;
  }
  .banner-all {
    position: relative;
    .elementor-column {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .banner-image {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 910px;
      height: 624px;
      object-fit: cover;
    }
    .selling-popular-gift-container {
      position: absolute;
      top: 0px;
      left: 0px;
      font-size: 49px;
      line-height: 69px;
      font-weight: 800;
      display: inline-block;
      width: 781px;
      height: 158px;
    }
    .sell-gift-cards-container {
      position: absolute;
      top: 100px;
      left: 0px;
      font-size: 23px;
      line-height: 40.5px;
      display: inline-block;
      width: 586px;
      height: 169px;
    }
    .selling-popular-gift {
      margin: 0;
    }
    &.no-animation {
      .animate {
        &__fadeInRight {
          animation-name: none;
        }
        &__fadeInLeft {
          animation-name: none;
        }
      }
    }
  }
  span.letters {
    font-family: 'Conv_Averta-Bold', Arial, sans-serif;
    font-weight: 700;
  }
  .elementor-widget-container {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(52, 58, 64, 0.08);
    color: #343a40 !important;
    padding: 10px 4px 10px 20px;
    background-color: #ffffff;
    border: 1px #eeeeee solid;
    margin: 10px 0;
  }
  .btn-warning {
    --bs-btn-border-radius: 80px;
    padding: 16px;
  }
  /*---- About Us ----*/
  #aboutus {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
    background-image: url(../../assets/img/georgia1.jpg);
    background-position: bottom;
    background-color: #ffffff;
    background-repeat: no-repeat;
    .content-aboutus {
      padding-top: 4rem !important;
    }
    h3.rt-title-heading {
      padding-bottom: 2rem !important;
    }
  }
  .d-flex {
    display: flex !important;
  }

  /*---- contactus ----*/
  #contactus {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
    .align-items-center h4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    p.register {
      &-success {
        color: #198754;
      }
      &-fail {
        color: rgb(220, 53, 69);
      }
    }
    .modal-dialog.modal-dialog-centered {
      z-index: 2000;
    }
  }

  .animate {
    &__fadeInLeft {
      -webkit-animation-name: fadeInLeft;
      animation-name: fadeInLeft;
    }

    &__fadeInRight {
      -webkit-animation-name: fadeInRight;
      animation-name: fadeInRight;
    }

    &__fadeInUp {
      -webkit-animation-name: fadeInUp;
      animation-name: fadeInUp;
    }

    &__animated {
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }
  }

  @keyframes fadeInRight {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }

  @-webkit-keyframes fadeInRight {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }

  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }

  @-webkit-keyframes fadeInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }

  @-webkit-keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  /* --------------- contact style-6 --------------- */
  .contact.style-6 {
    .content {
      position: relative;
      border-top: 10px solid #d74f11;
      border-top-right-radius: 40px;
      border-top-left-radius: 40px;
      padding: 100px 15px;

      &::before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: -o-linear-gradient(
          45deg,
          transparent,
          #ffdeb7,
          transparent,
          #ffdeb7,
          transparent
        );
        background: linear-gradient(
          45deg,
          transparent,
          #ffdeb7,
          transparent,
          #ffdeb7,
          transparent
        );
        background-size: 400% 400%;
        -webkit-animation: gradient 20s linear infinite;
        animation: gradient 20s linear infinite;
        border-radius: 30px;
        overflow: hidden;
      }
      .form {
        position: relative;
        z-index: 5;
        .form-group {
          &.mb-20 {
            margin-bottom: 20px !important;
          }
          .form-control {
            display: block;
            width: 100%;
            font-weight: 400;
            line-height: 1.5;
            color: #212529;
            background-color: #fff;
            background-clip: padding-box;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            transition: border-color 0.15s ease-in-out,
              box-shadow 0.15s ease-in-out;
            border: 1px solid rgba(153, 153, 153, 0.2666666667);
            border-radius: 30px;
            min-height: 50px;
            font-size: 16px;
            padding: 10px 25px;
            &:focus {
              border-color: black;
              -webkit-box-shadow: none;
              box-shadow: none;
            }
          }
          .text-danger {
            font-size: 14px;
          }
        }
      }
    }
  }
  @-webkit-keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .content-checkbox {
    display: inline-block;
    padding-bottom: 10px;
    a {
      color: #a73400;
      text-decoration: underline;
    }
  }
  .form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: 0.125rem;
    &.form-check-inline {
      display: inline-block;
      margin-right: 1rem;
    }
    .form-check-input {
      width: 30px !important;
      padding: 0 !important;
      min-height: 30px !important;
      margin-right: 10px;
      margin-top: 0em;
      &:checked {
        background-color: #0d6efd;
        border-color: #0d6efd;
      }
    }
  }

  /*----  Footer----*/
  .btn {
    padding: 8px 24px;

    font-size: 16px;
    transition: all 0.5s;
    font-family: 'Mandali', sans-serif;
    font-weight: 700;
  }
  .btn-warning {
    background-color: #f89820;

    &:disabled {
      background-color: darkgray;
      border-color: darkgray;
    }
  }
  .btn.btn-lg {
    padding: 12px 28px;
    font-size: 17px;
  }

  /*----  Mobile----*/
  /* Portrait and Landscape */
  @media only screen and (max-device-width: 1180px) and (-webkit-min-device-pixel-ratio: 2) {
    .hero-5 {
      padding: 180px 0px;
      padding-bottom: 0px;
    }
    .banner-all .banner-image {
      position: absolute;
      top: 142px;
      right: 0px;
      width: 747px;
      height: 500px;
      object-fit: cover;
    }
    .content-aboutus {
      padding-top: 0rem !important;
    }
    h3 {
      font-weight: 400;
      text-transform: capitalize;
      font-size: 32px;
    }
  }
  @media (max-width: 1024px) {
    .hero-5 {
      padding: 180px 0px;
      padding-bottom: 0px;
    }
    .banner-all .banner-image {
      position: absolute;
      top: 142px;
      right: 0px;
      width: 747px;
      height: 500px;
      object-fit: cover;
    }
    .content-aboutus {
      padding-top: 0rem !important;
    }
    h3 {
      font-weight: 400;
      text-transform: capitalize;
      font-size: 32px;
    }
  }

  @media only screen and (max-width: 844px) {
    .container {
      max-width: 820px;
    }
    .banner-all {
      .banner-image {
        position: absolute;
        top: 45px;
        /* left: 0px; */
        width: 500px;
        height: 342px;
        object-fit: initial;
        right: 0px;
      }
      .selling-popular-gift-container {
        position: relative;
        top: 0px;
        left: 0px;
        font-size: 16px;
        line-height: 69px;
        font-weight: 800;
        display: inline-block;
        width: 410px;
        height: 58px;
      }
      .sell-gift-cards-container {
        position: relative;
        top: 0px;
        left: 0px;
        font-size: 23px;
        line-height: 40.5px;
        display: inline-block;
        width: 414px;
        height: 330px;
      }
    }
    .hero-5 {
      padding: 180px 0px 0px 0px;
      position: relative;
      background-image: url(../../assets/img/bg_top.png);
      background-repeat: no-repeat;
      width: 100%;
      background-position: bottom center;
      background-size: cover;
      min-height: 300px;
    }
    h1 {
      font-size: 25px;
    }
    h2 {
      font-size: 18px;
      padding-bottom: 20px;
    }
    .content-aboutus {
      padding-top: 0rem !important;
    }
  }
  @media screen and (max-width: 767px) {
    .banner-all {
      position: relative;
      .selling-popular-gift-container {
        position: absolute;
        top: 0px;
        left: 0px;
        font-size: 10px;
        line-height: 18px;
        font-weight: 800;
        display: inline-block;
        width: 781px;
      }
      .sell-gift-cards-container {
        position: absolute;
        top: 52px;
        left: 0;
        font-size: 10px;
        display: inline-block;
        width: 586px;
        height: 169px;
      }
      .banner-image {
        position: absolute;
        top: 236px;
        bottom: 0px;
        right: 0px;
        width: 500px;
        height: 242px;
        object-fit: cover;
        .card-1-icon {
          top: -342px;
          left: 288px;
          object-fit: initial;
        }
      }
    }
    .hero-5 {
      padding: 130px 0px;
      padding-bottom: 48px;
      min-height: 608px;
    }
    .mb-20 {
      margin-bottom: 10px !important;
    }
    .elementor-column {
      width: 100%;
    }
    #aboutus .img-center {
      margin-bottom: 20px;
    }
    h1 {
      font-size: 18px;
    }
    h2 {
      line-height: 20px;
      font-size: 14px;
      padding-bottom: 16px;
    }
    .logo {
      width: 170px;
      height: 76px;
    }
    .d-flex {
      width: 50%;
    }

    #aboutus,
    #contactus {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    }
  }
}
