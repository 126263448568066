.omny-textInput {
  .form-control {
    font-weight: 700;
  }

  .spinner-border,
  img {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}
