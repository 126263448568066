.omny-phoneInput {
  .form-control {
    font-size: 2.75rem;
    font-weight: 700;
  }

  &.form-floating > .form-control {
    max-height: 89px;
    height: auto;
    line-height: 1.2;
  }
}
