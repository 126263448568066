.omny-registerTerm {
  padding: 0 1.5rem;
  margin-bottom: 0.5rem;

  #policy {
    padding-top: 200px;
  }

  h3 {
    color: #191d27;
  }

  a:link {
    color: #ff9f01;
  }

  @media screen and (max-width: 976px) {
    margin: 0;
  }
}
