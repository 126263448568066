@font-face {
  font-family: 'Conv_Averta-Light';
  src: url('../fonts/Averta-Light.eot');
  src: local('☺'), url('../fonts/Averta-Light.woff') format('woff'),
    url('../fonts/Averta-Light.ttf') format('truetype'),
    url('../fonts/Averta-Light.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Conv_Averta-Regular';
  src: url('../fonts/Averta-Regular.eot');
  src: local('☺'), url('../fonts/Averta-Regular.woff') format('woff'),
    url('../fonts/Averta-Regular.ttf') format('truetype'),
    url('../fonts/Averta-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Conv_Averta-Bold';
  src: url('../fonts/Averta-Bold.eot');
  src: local('☺'), url('../fonts/Averta-Bold.woff') format('woff'),
    url('../fonts/Averta-Bold.ttf') format('truetype'),
    url('../fonts/Averta-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Conv_Averta-Black';
  src: url('../fonts/Averta-Black.eot');
  src: local('☺'), url('../fonts/Averta-Black.woff') format('woff'),
    url('../fonts/Averta-Black.ttf') format('truetype'),
    url('../fonts/Averta-Black.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
