@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,400;0,700;0,900;1,400&display=swap');
@import './_colors';
@import './_variables';
@import './_fonts.scss';

:root {
  --ReactInputVerificationCode-itemWidth: 1rem;
  // --ReactInputVerificationCode-itemHeight: 5rem;
  // --ReactInputVerificationCode-itemSpacing: 1rem;
}

body {
  font-family: 'Conv_Averta-Regular', Arial, sans-serif;
  font-size: 1.2em;
  font-weight: normal;
  line-height: 1.6;
  color: #54585a;
  background-color: #fff;
  min-height: 100vh;
}

p {
  margin-bottom: 0.5rem;
}

p:last-child {
  margin-bottom: 0px;
}

.omny-layout {
  padding: 2rem 0;
}

h1 {
  font-family: Conv_Averta-Bold;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 42px;
  line-height: 1em;
  color: #191d27;
}
h2 {
  font-weight: 400;
  text-transform: capitalize;
  font-size: 18px;
  line-height: 30px;
  color: #191d27;
  padding-bottom: 20px;
}
h3 {
  font-weight: 400;
  text-transform: capitalize;
  font-size: 38px;
  line-height: 40px;
  color: #191d27;
}

/* unvisited link */
a:link {
  color: #ffe600;
  text-decoration: none;
}

/* mouse over link */
a:hover {
  color: #f89820;
  text-decoration: underline;
}
/* selected link */

a:active {
  color: #f89820;
}

@media screen and (max-width: 767px) {
  body {
    font-family: Conv_Averta-Regular, Arial, sans-serif;
    font-weight: 500;
    text-transform: capitalize;
    font-size: 16px;
  }

  .display-none {
    display: none;
  }
}
